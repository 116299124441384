import _get from 'lodash/get'
import Form from '@/services/forms/Form'
import { required } from 'vuelidate/lib/validators'

import { croppieDataFactory, mergeCroppieData } from '@hypefactors/shared/js/factories/croppie'

/**
 * @interface HF_OrganisationForm
 * @extends Form
 * @extends HF_OrganisationFormObject
 */

/**
 * Returns a new Form Instance for an Organisation
 * @return {HF_OrganisationForm}
 */
export function createOrganisationForm () {
  return new Form({
    name: { value: '', rules: { required } },
    vatNumber: { value: '', rules: {} },
    country: { value: '', rules: { required } },
    currency: { value: '', rules: {} },
    logo: { value: croppieDataFactory() },
    lockout_time: { value: 0 },
    description: { value: '', rules: {} },
    domain: { value: '', rules: {} },
    color_primary: { value: '', rules: {} },
    color_secondary: { value: '', rules: {} },
    font_primary: { value: '', rules: {} }
  })
}

/**
 * @interface HF_OrganisationFormObject
 * @property {string} name
 * @property {string} vatNumber
 * @property {string} country
 * @property {string} currency
 * @property {croppieData} logo
 */

/**
 * Prepares the Organisation for Editing
 * @param {HF_Organisation} organisation
 * @return {HF_OrganisationFormObject}
 */
export function prepareOrganisationFormObject (organisation) {
  return {
    name: organisation.name,
    vatNumber: organisation.vatNumber,
    country: _get(organisation, 'country.iso_alpha2', null),
    currency: organisation.currencyCode,
    // industry: organisation.industry, // for later?
    logo: mergeCroppieData(organisation.logo),
    lockout_time: organisation.lockout_time,
    description: organisation.description,
    domain: organisation.domain,
    color_primary: organisation.color_primary,
    color_secondary: organisation.color_secondary,
    font_primary: organisation.font_primary
  }
}

export function createOrganisationFormRequest (organisation) {
  return {
    ...organisation,
    vat_number: organisation.vatNumber
  }
}
