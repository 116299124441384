<template>
  <div ref="topTags" class="chart" />
</template>

<script>
import BarChart from '@hypefactors/shared/js/components/charts/base/BarChart'

export default {
  props: {
    tags: {
      type: Array,
      default: () => ([])
    }
  },

  computed: {
    tagsTitle () {
      return this.tags.map((tag) => tag.title)
    },

    tagsData () {
      return this.tags.map((tag) => tag.total)
    }
  },

  watch: {
    tags () {
      this.drawChart()
    }
  },

  methods: {
    drawChart () {
      this.$barChart = new BarChart(
        this.$refs.topTags,
        this.tagsTitle,
        [
          {
            data: this.tagsData
          }
        ],
        {
          monochrome: true
        }
      )
    }
  }
}
</script>
