<template>
  <layout name="NoColumns">
    <iframe
      id="trends-embedded"
      class="iframe"
      src="https://my-vue-app-hz7d5dkymq-lz.a.run.app"
      allowtransparency="true"
      @load="resizeIframe"
    />
  </layout>
</template>

<script>
import { loadScript } from '@hypefactors/shared/js/utils'

export default {
  methods: {
    resizeIframe () {
      window.iFrameResizer = {}

      function resizeAfterDelay (delay) {
        setTimeout(() => {
          window.iFrameResize({
            license: 'GPLv3',
            waitForLoad: true
          }, 'trends-embedded')
        }, delay)
      }

      loadScript('https://unpkg.com/iframe-resizer@5.3.2/js/iframeResizer.min.js', 'iFrameResizer')
        .then(() => {
          resizeAfterDelay.call(this, 3000)
        })
    }
  }
}
</script>

<style lang="scss" scoped>
.iframe {
  width: 100%;
  height: 100vh;
  border-style: none;
  overflow: hidden;
}
</style>
