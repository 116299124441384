import Vue from 'vue'
import Loading from 'element-ui/lib/loading'
import Message from 'element-ui/lib/message'
import MessageBox from 'element-ui/lib/message-box'
import Notification from 'element-ui/lib/notification'
import ElementLocale from 'element-ui/lib/locale/index'
import ColorPicker from 'element-ui/lib/color-picker'

import { i18n } from './i18n'

// Pagination,
import Dialog from 'element-ui/lib/dialog'
// Autocomplete,
// Menu,
// Submenu,
// MenuItem,
// MenuItemGroup,
// Input,
// InputNumber,
// RadioButton,
// import Button from 'element-ui/lib/button'
// ButtonGroup,
// Table,
// TableColumn,
// import DatePicker from 'element-ui/lib/date-picker'
// TimeSelect,
// TimePicker,
import Tooltip from 'element-ui/lib/tooltip'
// Breadcrumb,
// BreadcrumbItem,
// Form,
// FormItem,
// Tabs,
// TabPane,
// Tag,
// Tree,
// Alert,
// Slider,
// Icon,
// Row,
// Col,
// Progress,
// Spinner,
// Badge,
// Card,
// Rate,
import Scrollbar from 'element-ui/lib/scrollbar'
// Cascader,
// Vue.use(Pagination)
Vue.use(Dialog)
// Vue.use(Autocomplete)
Vue.component('ElDropdown', () => import(/* webpackChunkName: "ElDropdown" */ 'element-ui/lib/dropdown'))
Vue.component('ElDropdownMenu', () => import(/* webpackChunkName: "ElDropdownMenu" */ 'element-ui/lib/dropdown-menu'))
Vue.component('ElDropdownItem', () => import(/* webpackChunkName: "ElDropdownItem" */ 'element-ui/lib/dropdown-item'))
// Vue.use(Menu)
// Vue.use(Submenu)
// Vue.use(MenuItem)
// Vue.use(MenuItemGroup)
// Vue.use(Input)
// Vue.use(InputNumber)
Vue.component('ElRadio', () => import(/* webpackChunkName: "ElRadio" */ 'element-ui/lib/radio'))
Vue.component('ElRadioGroup', () => import(/* webpackChunkName: "ElRadioGroup" */ 'element-ui/lib/radio-group'))
// Vue.use(RadioButton)
Vue.component('ElCheckbox', () => import(/* webpackChunkName: "ElCheckbox" */ 'element-ui/lib/checkbox'))
Vue.component('ElCheckboxGroup', () => import(/* webpackChunkName: "ElCheckboxGroup" */ 'element-ui/lib/checkbox-group'))
Vue.component('ElSwitch', () => import(/* webpackChunkName: "ElSwitch" */ 'element-ui/lib/switch'))
Vue.component('ElSelect', () => import(/* webpackChunkName: "ElSelect" */ 'element-ui/lib/select'))
Vue.component('ElOption', () => import(/* webpackChunkName: "ElOption" */ 'element-ui/lib/option'))
Vue.component('ElOptionGroup', () => import(/* webpackChunkName: "ElOptionGroup" */ 'element-ui/lib/option-group'))
// Vue.use(Button)
// Vue.use(ButtonGroup)
// Vue.use(Table)
// Vue.use(TableColumn)
Vue.component('ElDatePicker', () => import(/* webpackChunkName: "ElDatePicker" */ 'element-ui/lib/date-picker'))
// Vue.use(TimeSelect)
// Vue.use(TimePicker)
Vue.component('ElPopover', () => import(/* webpackChunkName: "ElPopover" */ 'element-ui/lib/popover'))
Vue.use(Tooltip)
// Vue.use(Breadcrumb)
// Vue.use(BreadcrumbItem)
// Vue.use(Form)
// Vue.use(FormItem)
// Vue.use(Tabs)
// Vue.use(TabPane)
// Vue.use(Tag)
// Vue.use(Tree)
// Vue.use(Alert)
// Vue.use(Slider)
// Vue.use(Icon)
// Vue.use(Row)
// Vue.use(Col)
Vue.component('ElUpload', () => import(/* webpackChunkName: "ElUpload" */ 'element-ui/lib/upload'))
// Vue.use(Progress)
// Vue.use(Spinner)
// Vue.use(Badge)
// Vue.use(Card)
// Vue.use(Rate)
Vue.component('ElSteps', () => import(/* webpackChunkName: "ElSteps" */ 'element-ui/lib/steps'))
Vue.component('ElStep', () => import(/* webpackChunkName: "ElStep" */ 'element-ui/lib/step'))
Vue.component('ElCarousel', () => import(/* webpackChunkName: "ElCarousel" */ 'element-ui/lib/carousel'))
Vue.component('ElCarouselItem', () => import(/* webpackChunkName: "ElCarouselItem" */ 'element-ui/lib/carousel-item'))
Vue.component('ElCollapse', () => import(/* webpackChunkName: "ElCollapse" */ 'element-ui/lib/collapse'))
Vue.component('ElCollapseItem', () => import(/* webpackChunkName: "ElCollapseItem" */ 'element-ui/lib/collapse-item'))
// Vue.use(Cascader)
Vue.component(ColorPicker.name, ColorPicker)
//
Vue.use(Scrollbar)
Vue.use(Loading.directive)

Vue.prototype.$loading = Loading.service
Vue.prototype.$msgbox = MessageBox
Vue.prototype.$alert = MessageBox.alert
Vue.prototype.$confirm = MessageBox.confirm
Vue.prototype.$prompt = MessageBox.prompt
Vue.prototype.$notify = Notification
Vue.prototype.$message = Message

ElementLocale.i18n((key, value) => i18n.t(key, value))
