<template>
  <div
    v-if="isVisible"
    class="Relation-fact-wrapper"
  >
    <div class="Relation-fact-item columns">
      <div class="column is-1 is-flex is-aligned-middle is-aligned-center">
        <div class="icon is-size-3">
          <v-icon
            :class="{ 'has-text-primary': displayDetails }"
            type="email"
          />
        </div>
      </div>

      <div
        class="column is-flex is-column is-aligned-center"
        :class="{
          'is-3': !email.is_scheduled,
          'is-9': email.is_scheduled,
        }"
      >
        <h4 :title="email.subject">
          <span>{{ truncate(email.subject, 30) }}</span>
        </h4>

        <small>
          <div
            v-if="email.is_scheduled"
            class="mt-2"
          >
            {{ $t('components.relation_fact_item.scheduled_time') }} {{ datetimeTz(email.schedule_send_at, email.scheduling_config.timezone) }} {{ getTimezoneString(email.scheduling_config.timezone) }}
          </div>
          <div
            v-else
            class="mt-2"
          >
            {{ $t('components.relation_fact_item.sent') }}: {{ datetime(email.created_at, 'DD MMMM YYYY - HH:mm') }}
          </div>

          <div class="mt-2">
            {{ email.recipients_count }} {{ $t('components.relation_fact_item.recipients') }}
          </div>
        </small>
      </div>

      <template v-if="email.is_scheduled">
        <div class="column is-2 link-section is-aligned-end is-aligned-middle">
          <div class="link-item">
            <router-link
              :to="{ name: 'emails.edit', params: { emailId: email.id } }"
              class="button is-dark"
            >
              {{ $t('forms.edit') }}
            </router-link>
          </div>

          <div class="link-item">
            <v-button
              class="button is-primary"
              @click.prevent="stopTracking"
            >
              {{ $t('general.delete') }}
            </v-button>
          </div>
        </div>
      </template>

      <template v-else>
        <div class="column is-3 is-flex is-aligned-center">
          <email-event-percentage-chart :percentage="email.stats.opened_rate" event="open" />
        </div>

        <div class="column is-2 is-flex is-aligned-middle">
          <email-event-percentage-chart :percentage="email.stats.clicked_rate" event="click" />
        </div>

        <div class="column is-3 link-section is-aligned-end is-aligned-middle">
          <div class="link-item">
            <restricted-button
              :loading="isLoading"
              permission="emails.list"
              action-class="button is-dark is-plain"
              data-testid="OpenDetailsPaneTrigger"
              @click.prevent="openDetailsPane"
            >
              {{ displayDetails ? $t('forms.close') : $t('forms.open') }}
            </restricted-button>

            <router-link
              title="Clone"
              :to="{ name: 'emails.new', query: { emailId: email.id, copy: true } }"
              :disabled="isLoading"
              class="button is-text"
            >
              <v-icon type="clone" />
              <span class="ml-1">{{ $t('forms.clone') }}</span>
            </router-link>
          </div>

          <div class="link-item">
            <el-tooltip
              :content="$t('pages.relations.facts.stop_tracking_hover')"
              trigger="hover"
            >
              <restricted-button
                :loading="isLoading"
                permission="emails.list"
                action-class="button is-dark is-plain"
                @click.prevent="stopTracking"
              >
                {{ $t('pages.relations.facts.stop_tracking') }}
              </restricted-button>
            </el-tooltip>
          </div>
        </div>
      </template>
    </div>

    <div
      v-if="displayDetails"
      class="Relation-fact-recipient is-multiline"
    >
      <div
        v-if="openedBy.length"
        class="column is-offset-1"
      >
        <h3>{{ $t('components.relation_fact_item.opened_by') }}:</h3>
      </div>

      <recipient
        v-for="recipient in openedBy"
        :key="recipient.id"
        :recipient="recipient"
      />

      <div
        v-if="notOpenedBy.length"
        class="column is-offset-1"
      >
        <h3>{{ $t('components.relation_fact_item.not_opened_by') }}:</h3>
      </div>

      <recipient
        v-for="recipient in notOpenedBy"
        :key="recipient.id"
        :recipient="recipient"
      />
    </div>
  </div>
</template>

<script>
import TruncateMixin from '@hypefactors/shared/js/mixins/TruncateMixin'
import DatetimeMixin from '@hypefactors/shared/js/mixins/DatetimeMixin'
import DatetimeTzMixin from '@hypefactors/shared/js/mixins/DatetimeTzMixin'

import timezones from '@/constants/timezones'

import Recipient from './Recipient'
import EmailEventPercentageChart from '@/components/charts/EmailEventPercentageChart'

export default {
  components: {
    Recipient,
    EmailEventPercentageChart
  },
  mixins: [TruncateMixin, DatetimeMixin, DatetimeTzMixin],
  props: {
    email: {
      type: Object,
      required: true
    }
  },

  data () {
    return {
      isLoading: false,
      isVisible: true,
      recipients: [],
      displayDetails: false
    }
  },

  computed: {
    openedBy () {
      return this.recipients
        .filter(r => r.opened_email)
    },

    notOpenedBy () {
      return this.recipients
        .filter(r => !r.opened_email)
    }
  },

  methods: {
    getTimezoneString (timezone) {
      return timezones[timezone]
    },

    toggleDetailsPane () {
      this.displayDetails = !this.displayDetails
    },

    async openDetailsPane () {
      if (this.recipients.length) {
        this.toggleDetailsPane()
        return
      }

      await this.fetchRecipients()
      this.toggleDetailsPane()
    },

    stopTracking () {
      return this.$api.delete(`emails/${this.email.id}`)
        .then(() => {
          this.isVisible = false

          this.$emit('refresh')
        })
        .catch((error) => {
          this.$displayRequestError(error)
        })
    },

    fetchRecipients () {
      this.isLoading = true
      return this.$api.get(`emails/${this.email.id}/recipients`)
        .then(({ data }) => {
          this.isLoading = false
          this.recipients = data.data
        })
        .catch((error) => {
          this.isLoading = false
          this.$displayRequestError(error)
        })
    }
  }
}
</script>

<style lang="scss">
@import "~utils";

.Relation-fact-wrapper {
  border-bottom: 1px solid $grey-lighter;

  h3 {
    font-weight: bold;
    border-bottom: 1px solid $grey-lighter;
  }
}

.Relation-fact-item {
  margin: 0 !important;

  h4 {
    font-weight: bold;
  }

  small {
    font-size: $hf__font-label-small;
  }
}

  .link-section {
    display: flex;
    flex-wrap: wrap;

    .link-item {
      flex: 1 50px;
      margin: 5px 5px;
    }
  }
</style>
