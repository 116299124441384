<template>
  <banded-section
    title="Create User"
    :collapsable="false"
    class="CreateUser"
  >
    <template slot="tooltip">
      <div class="m-b-m">
        This section is visible to the Hypefactors team only.
      </div>
      <div class="m-b-m">
        Create a new User and with Organisation account and Brand/Newsroom.
      </div>
      <div class="m-b-m">
        Or add new User and assign him as member of given Brands with selected role.
      </div>
    </template>
    <heading size="3">
      Create User
    </heading>
    <div v-loading="fetchingRoles">
      <form @submit.prevent="submit">
        <div class="columns is-multiline">
          <div class="column">
            <el-radio v-model="creationType" label="new">
              <strong> Create complete account with User, Brand & Newsroom</strong>
            </el-radio>
          </div>
          <div class="column">
            <el-radio v-model="creationType" label="existing">
              <strong>Only create User and assign to existing Brands</strong>
            </el-radio>
          </div>
        </div>
        <div id="names" class="columns">
          <div class="column">
            <form-field :validator="$v.form.first_name" label="First Name">
              <input
                v-model="form.first_name"
                class="input"
                type="text"
                name="name"
                placeholder="First Name"
                @input="$v.form.first_name.$touch()"
              >
            </form-field>
          </div>
          <div class="column">
            <form-field :validator="$v.form.last_name" label="Last Name">
              <input
                v-model="form.last_name"
                class="input"
                type="text"
                name="surname"
                placeholder="Last Name"
                @input="$v.form.last_name.$touch()"
              >
            </form-field>
          </div>
        </div>
        <div id="email_password" class="columns">
          <div class="column">
            <form-field :validator="$v.form.email" label="Email">
              <input
                v-model="form.email"
                class="input"
                type="email"
                name="email"
                placeholder="User Email"
                @input="$v.form.email.$touch()"
              >
            </form-field>
          </div>
          <div class="column">
            <form-field :validator="$v.form.password" label="Password">
              <input
                v-model="form.password"
                class="input"
                type="text"
                name="password"
                placeholder="User Password"
                @input="$v.form.password.$touch()"
              >
            </form-field>
          </div>
          <div class="column">
            <form-field
              :validator="$v.form.language"
              :label="$t('general.language')"
            >
              <language-picker
                v-model="form.language"
                :placeholder="$t('forms.choose_language')"
                popper-class="Preferences_LanguageModal"
                class="Preferences_LanguagePicker"
                show-supported-only
                show-language-variations
              />
            </form-field>
          </div>
        </div>
        <template v-if="!isCreatingOrganisationAccount">
          <heading size="5">
            Brand Details
          </heading>
          <v-separator />
          <div id="organisations" class="columns">
            <div class="column">
              <form-field
                :validator="$v.form.organisation"
                label="Organisation"
              >
                <organisations-picker
                  :value="form.organisation"
                  popper-class="OrganisationsModal"
                  @input="handleOrganisationSelect"
                />
              </form-field>
            </div>
            <div class="column">
              <form-field
                :validator="$v.form.organisation_role"
                label="Organisation Role"
              >
                <basic-select
                  v-model="form.organisation_role"
                  :loading="fetchingRoles"
                  :values="organisationRolesToPickFrom"
                  label-prop="name"
                  value-prop="slug"
                  key-prop="slug"
                />
              </form-field>
            </div>
          </div>
          <div id="brands" class="columns">
            <div class="column">
              <form-field :validator="$v.form.brands" label="Brands">
                <basic-select
                  v-model="form.brands"
                  :values="brandsToPickFrom"
                  label-prop="name"
                  value-prop="id"
                  key-prop="id"
                  multiple
                />
              </form-field>
            </div>
            <div class="column">
              <form-field
                :validator="$v.form.brand_role"
                label="Brand Role"
              >
                <basic-select
                  v-model="form.brand_role"
                  :loading="fetchingRoles"
                  :values="brandRolesToPickFrom"
                  label-prop="name"
                  value-prop="slug"
                  key-prop="slug"
                />
              </form-field>
            </div>
          </div>
        </template>
        <template v-else>
          <heading size="5">
            Organisation Details
          </heading>
          <v-separator />
          <div class="columns">
            <div class="column is-half">
              <form-field
                :validator="$v.form.organisation_name"
                label="Organisation Name"
              >
                <input
                  v-model="form.organisation_name"
                  class="input"
                  type="text"
                  name="organisation_name"
                  placeholder="Organisation Name"
                >
              </form-field>
            </div>
            <div class="column is-half">
              <form-field
                :validator="$v.form.organisation_vat"
                label="Organisation VAT Number"
              >
                <input
                  v-model="form.organisation_vat"
                  class="input"
                  type="text"
                  name="organisation_vat"
                  placeholder="VAT Number"
                >
              </form-field>
            </div>
          </div>
          <heading size="5">
            Subscription Details
          </heading>
          <v-separator />
          <div class="columns">
            <div class="column">
              <form-field :validator="$v.form.plan_slug" label="Plan">
                <el-select
                  v-model="form.plan_slug"
                  placeholder="Choose Plan"
                  popper-class="Preferences_PlansModal"
                >
                  <el-option-group label="Monthly">
                    <el-option label="Freemium" value="freemium-monthly-2" />
                    <el-option label="Plus" value="plus-monthly-3" />
                    <el-option label="Pro" value="professional-monthly-2" />
                  </el-option-group>
                  <el-option-group label="Yearly">
                    <el-option label="Plus" value="plus-yearly-3" />
                    <el-option label="Pro" value="professional-yearly-2" />
                  </el-option-group>
                </el-select>
              </form-field>
            </div>
            <div class="column">
              <form-field
                :validator="$v.form.trial_end"
                label="Trial Period"
              >
                <el-date-picker
                  v-model="form.trial_end"
                  :picker-options="pickerOptions"
                  type="date"
                  placeholder="Trial period ends at"
                  value-format="timestamp"
                />
              </form-field>
            </div>
          </div>
          <heading size="5">
            Brand Details
          </heading>
          <v-separator />
          <div id="brand_newsroom" class="columns">
            <div class="column is-half">
              <form-field
                :validator="$v.form.brand_name"
                label="Brand Name"
              >
                <input
                  v-model="form.brand_name"
                  class="input"
                  type="text"
                  name="brand_name"
                  placeholder="Brand Name"
                >
              </form-field>
            </div>
            <div class="column is-half">
              <form-field label="Newsroom Name">
                <input
                  v-model="form.newsroom_name"
                  class="input"
                  type="text"
                  name="newsroom_name"
                  placeholder="Newsroom will be same as Brand name if not set"
                >
              </form-field>
            </div>
          </div>
        </template>
        <heading size="5">
          General
        </heading>
        <v-separator />
        <div id="country_timezone" class="columns">
          <div class="column">
            <form-field
              :validator="$v.form.country_iso2"
              label="Country"
            >
              <country-picker
                v-model="form.country_iso2"
                popper-class="StaffManualUser__countryModal"
                placeholder="Choose User country"
              />
            </form-field>
          </div>
          <div class="column">
            <form-field :validator="$v.form.timezone" :label="$t('general.time_zone')">
              <el-select
                v-model="form.timezone"
                :placeholder="$t('forms.choose_time_zone')"
                filterable
                popper-class="Preferences_TimezoneModal"
              >
                <el-option
                  v-for="(zone, key) in timezones"
                  :key="zone"
                  :label="zone"
                  :value="key"
                />
              </el-select>
            </form-field>
          </div>
        </div>
        <label for="cc-num" class="is-hidden">HubspotCollectedFormsWorkaround</label>
        <input
          id="cc-num"
          name="cc-num"
          class="is-hidden"
          required=""
          value="HubspotCollectedFormsWorkaround"
        >
        <div class="has-text-right">
          <v-button
            :loading="form.isLoading"
            type="submit"
            class="is-primary"
          >
            Create
          </v-button>
        </div>
      </form>
    </div>
  </banded-section>
</template>

<script>
import Form from '@/services/forms/Form'
import timezones from '@/constants/timezones'
import { required, requiredIf, email } from 'vuelidate/lib/validators'
import OrganisationsPicker from '@/components/forms/OrganisationsPicker'
import { isPasswordStrongEnough } from '@hypefactors/shared/js/utils/validation'
import _omit from 'lodash/omit'

function createForm () {
  return new Form({
    organisation: {
      value: null,
      rules: {
        required: requiredIf(function () {
          return !this.isCreatingOrganisationAccount
        })
      }
    },
    organisation_role: {
      value: null,
      rules: {
        required: requiredIf(function () {
          return !this.isCreatingOrganisationAccount
        })
      }
    },
    brand_role: {
      value: null,
      rules: {
        required: requiredIf(function () {
          return !this.isCreatingOrganisationAccount
        })
      }
    },
    brands: {
      value: [],
      rules: {
        required: requiredIf(function () {
          return !this.isCreatingOrganisationAccount
        })
      }
    },
    brand_name: {
      value: '',
      rules: {
        required: requiredIf(function () {
          return this.isCreatingOrganisationAccount
        })
      }
    },
    organisation_name: {
      value: '',
      rules: {
        required: requiredIf(function () {
          return this.isCreatingOrganisationAccount
        })
      }
    },
    organisation_vat: {
      value: '',
      rules: {}
    },
    plan_slug: {
      value: '',
      rules: {
        required: requiredIf(function () {
          return this.isCreatingOrganisationAccount
        })
      }
    },
    trial_end: {
      value: '',
      rules: {}
    },
    first_name: { value: '', rules: { required } },
    last_name: { value: '', rules: { required } },
    email: { value: '', rules: { required, email } },
    password: { value: '', rules: { required, 'password.strength': isPasswordStrongEnough(6) } },
    language: { value: '', rules: { required } },
    country_iso2: { value: '', rules: { required } },
    timezone: { value: 'Europe/Copenhagen', rules: { required } },
    type: { value: 'company' },
    newsroom_name: { value: '' }
  })
}

export default {
  components: { OrganisationsPicker },

  data: () => ({
    form: createForm(),
    roles: [],
    brands: [],
    results: [],
    organisations: [],
    fetchingRoles: false,
    fetchingOrganisations: false,
    creationType: 'new',
    pickerOptions: {
      disabledDate (v) {
        return v < Date.now()
      }
    }
  }),

  computed: {
    brandsToPickFrom () {
      return this.$safeGet(this.form.organisation, 'brands.data', [])
    },

    brandRolesToPickFrom () {
      return this.roles.filter((role) => role.type === 'brand')
    },

    organisationRolesToPickFrom () {
      return this.roles.filter((role) => role.type === 'organisation')
    },

    timezones () {
      return timezones
    },

    isCreatingOrganisationAccount () {
      return this.creationType === 'new'
    }
  },

  validations () {
    return {
      form: this.form.rules()
    }
  },

  mounted () {
    this.fetchRoles()
  },

  beforeRouteLeave (to, from, next) {
    if (!this.form.hasChanges()) return next()
    return this.$confirm(this.$t('general.confirm_unsaved_changes')).then(() => {
      this.form.reset()
      next()
    }).catch(() => { })
  },

  methods: {
    handleOrganisationSelect (value) {
      this.form.brands = []
      this.form.organisation = value
    },

    fetchRoles () {
      this.fetchingRoles = true
      this.$api.get('roles')
        .then((response) => {
          this.fetchingRoles = false
          this.roles = response.data.data
        })
        .catch((error) => {
          this.fetchingRoles = false
          this.$displayRequestError(error, this.$t('errors.cannot_fetch_roles'))
        })
    },

    async submit () {
      this.$v.form.$touch()
      if (this.$v.form.$error) return
      try {
        this.form.setPayloadTransformer((payload) => {
          payload.trial_end = payload.trial_end ? (payload.trial_end / 1000) : ''

          if (this.isCreatingOrganisationAccount) {
            return _omit(payload, ['brands', 'brand_role', 'organisation'])
          }

          payload.organisation_id = payload.organisation.id
          delete payload.organisation

          return payload
        })

        this.form.post('/staff/users')
          .then(() => {
            this.$notify.success('The User was successfully created.')
            this.form.reset()
            this.$v.form.$reset()
            this.fetchRoles()
          })
          .catch((error) => {
            this.$displayRequestError(error)
          })
      } catch (err) {}
    }
  }
}
</script>
