<template>
  <div
    v-loading="isLoading"
    class="CoverageTopTagsDataProvider"
  >
    <slot :data="tags" />
  </div>
</template>

<script>
import * as MetricsService from '@hypefactors/shared/js/services/api/MetricsService'
import { metricsDataProviderMixinFactory } from '@hypefactors/shared/js/components/charts/MetricsDataProviderMixin'

/**
 * @module CoverageTopTagsDataProvider
 */
export default {
  name: 'CoverageTopTagsDataProvider',

  mixins: [metricsDataProviderMixinFactory({ watchFor: 'appliedCoverageFilters', vuexModule: 'coverage' })],

  data () {
    return {
      tags: []
    }
  },

  methods: {
    fetchData () {
      return MetricsService.fetchTopTags({
        params: this.appliedCoverageFilters,
        cancelToken: this.cancelToken.token
      })
        .then((response) => {
          this.tags = response.tags
        })
    }
  }
}
</script>
