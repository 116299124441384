<template>
  <CoverageTopTagsDataProvider
    #default="{ data: tags }"
    class="Page__content-body"
  >
    <TopTagsChart :tags="tags" />
  </CoverageTopTagsDataProvider>
</template>

<script>
import TopTagsChart from '../TopTags.vue'
import CoverageTopTagsDataProvider from '../../tables/CoverageTopTagsDataProvider.vue'

/**
 * @module CoverageTopTags
 */
export default {
  name: 'CoverageTopTags',

  components: {
    CoverageTopTagsDataProvider,
    TopTagsChart
  }
}
</script>
