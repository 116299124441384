<template>
  <el-dialog
    :title="modalTitle"
    :visible.sync="visibleLocal"
    class="MediaNotificationManagementModal is-fullscreen-mobile"
    @closed="closed"
  >
    <form data-testid="MediaNotificationsManagement__Form" @submit.prevent="submit">
      <form-wrapper
        v-loading="isFetchingStreams"
        :validator="$v.form"
      >
        <form-field
          key="is_enabled"
          :label="$t('general.enabled')"
          name="is_enabled"
        >
          <el-switch v-model="form.is_enabled" />
        </form-field>

        <form-field :label="$t('forms.media_notifications.title')" name="title">
          <input
            v-model="validator.$model"
            slot-scope="{ validator, classes, placeholder }"
            :class="classes"
            :placeholder="placeholder"
            class="input"
            type="text"
            data-testid="TitleInput"
          >
        </form-field>

        <form-field
          key="summarize"
          :label="$t('forms.media_notifications.summarize')"
          name="summarize"
        >
          <el-switch v-model="form.summarize" />
        </form-field>

        <form-field
          key="no_new_mentions_email"
          :label="$t('forms.media_notifications.no_new_mentions_email')"
          name="no_new_mentions_email"
        >
          <el-switch v-model="form.no_new_mentions_email" />
        </form-field>

        <form-field
          key="sorting_type"
          label="Sorting by"
          name="sorting_type"
        >
          <basic-select
            v-model="validator.$model"
            slot-scope="{ validator }"
            :values="sortingTypeOptions"
            label-prop="label"
            key-prop="value"
            value-prop="value"
          />
        </form-field>

        <form-field
          key="density"
          label="Density"
          name="density"
        >
          <basic-select
            v-model="validator.$model"
            slot-scope="{ validator }"
            :values="densityOptions"
            label-prop="label"
            key-prop="value"
            value-prop="value"
          />
        </form-field>

        <form-field
          key="streams"
          :label="$t('general.streams')"
          name="streams"
        >
          <basic-select
            v-model="validator.$model"
            slot-scope="{ validator }"
            :values="streams"
            multiple
            label-prop="title"
            key-prop="id"
            value-prop="id"
          >
            <span slot-scope="{ item }">{{ `${item.brand_name} - ${item.title}` }}</span>
          </basic-select>
        </form-field>

        <form-field
          key="days"
          :label="$t('forms.media_notifications.days')"
          name="days"
        >
          <label
            v-for="dayOfWeek in daysOfWeek"
            :key="dayOfWeek.value"
            class="button m-r-s border-2"
            :class="{
              'border-black': form.days.includes(dayOfWeek.value)
            }"
          >
            <input
              v-model="form.days"
              type="checkbox"
              :value="dayOfWeek.value"
              class="is-hidden"
            >
            {{ dayOfWeek.label }}
          </label>
        </form-field>

        <form-field
          key="timezone"
          :label="$t('general.timezone')"
          name="timezone"
        >
          <timezone-picker
            v-model="form.timezone"
          />
        </form-field>

        <form-field :label="$t('forms.media_notifications.time_schedules')">
          <table class="table is-tranparent is-full-width m-l-none m-b-none">
            <tbody>
              <tr v-for="(schedule, index) in form.schedules" :key="schedule.time">
                <td class="p-l-none cell-aligned-middle is-narrow has-text-weight-semibold">
                  #{{ index + 1 }}
                </td>
                <td class="p-l-none is-width-1/4">
                  <el-select
                    v-model="schedule.time"
                    filterable
                  >
                    <el-option
                      v-for="time in times"
                      :key="time.value"
                      :label="time.label"
                      :value="time.value"
                      :disabled="time.disabled"
                    />
                  </el-select>
                </td>
                <td class="cell-aligned-middle has-text-right p-r-none">
                  <v-button
                    type="button"
                    class="is-plain is-black p-l-none p-r-none m-l-m"
                    :disabled="form.schedules.length === 1"
                    @click="removeTimeSchedule(schedule)"
                  >
                    <v-icon type="trash" />
                  </v-button>
                </td>
              </tr>
            </tbody>
          </table>
        </form-field>

        <v-button
          class="m-t-s m-b-s is-dark is-small"
          type="button"
          @click="addTimeSchedule"
        >
          {{ $t('forms.media_notifications.add_time_schedule') }}
        </v-button>

        <hr class="m-v-m">

        <div class="buttons is-right">
          <v-button
            :loading="form.isLoading"
            class="is-black"
            data-testid="MediaNotificationsManagement__Submit"
          >
            {{ $t('forms.save') }}
          </v-button>
        </div>
      </form-wrapper>
    </form>
  </el-dialog>
</template>

<script>
import moment from 'moment'
import { mapGetters } from 'vuex'
import { required } from 'vuelidate/lib/validators'

import Form from '@/services/forms/Form'
import TimezonePicker from '@/components/core/TimezonePicker'
import { MediaNotificationsApiService } from '@/services/api/MediaNotificationsApiService'
import { UserApiService } from '@hypefactors/shared/js/services/api/UserApiService'
import { generateComputedSetterWithEmit } from '@hypefactors/shared/js/utils/componentUtilities'

/**
 * @module MediaNotificationManagementModal
 */
export default {
  name: 'MediaNotificationManagementModal',

  components: { TimezonePicker },

  props: {
    visible: {
      type: Boolean,
      default: false
    },

    notification: {
      type: Object,
      default: null
    }
  },

  data () {
    return {
      isFetchingStreams: false,

      daysOfWeek: [
        { label: 'Monday', value: 'mon' },
        { label: 'Tuesday', value: 'tue' },
        { label: 'Wednesday', value: 'wed' },
        { label: 'Thursday', value: 'thu' },
        { label: 'Friday', value: 'fri' },
        { label: 'Saturday', value: 'sat' },
        { label: 'Sunday', value: 'sun' }
      ],

      sortingTypeOptions: [
        { label: 'Hype - impact first', value: 'hype' },
        { label: 'Popularity - positive first', value: 'popularity' },
        { label: 'Protection - negative first', value: 'protection' }
      ],

      densityOptions: [
        { label: 'Comfortable', value: 'comfortable' },
        { label: 'Compact', value: 'compact' }
      ],

      streams: [],

      form: new Form({
        title: { value: '', rules: { required } },
        is_enabled: { value: true, rules: { required } },
        sorting_type: { value: 'hype', rules: { required } },
        density: { value: 'comfortable', rules: { required } },
        streams: { value: [], rules: { required } },
        timezone: { value: '', rules: { required } },
        days: { value: [], rules: { required } },
        schedules: { value: [], rules: { required } },
        no_new_mentions_email: { value: true, rules: { required } },
        summarize: { value: true, rules: { required } }
      })
    }
  },

  validations () {
    return {
      form: this.form.rules()
    }
  },

  computed: {
    ...mapGetters(['currentUser']),

    visibleLocal: generateComputedSetterWithEmit('visible', 'update:visible'),

    isNew () {
      return !this.notification
    },

    modalTitle () {
      const attribute = this.isNew ? 'create_title' : 'update_title'

      return this.$t(`components.media_notification_management_modal.${attribute}`)
    },

    times () {
      let times = []

      Array.from({
        length: 48
      }, (_, hour) => {
        const time = moment({
          hour: Math.floor(hour / 2),
          minutes: (hour % 2 === 0 ? 0 : 30)
        })

        const value = time.format('HH:mm:ss')

        times.push({
          value: value,
          label: time.format('HH:mm'),
          disabled: this.form.schedules.map(t => t.time).indexOf(value) >= 0
        })
      })

      return times
    }
  },

  watch: {
    visible (isVisible) {
      if (!isVisible) return

      this.fetchStreams()

      if (this.isNew) {
        this.form.reset()
        this.$v.form.$reset()

        this.form.timezone = this.currentUser.timezone
        this.form.schedules = [
          { time: '07:30:00' }
        ]
      } else {
        // Hydrate the editing form
        this.hydrateForm()
      }
    }
  },

  methods: {
    hydrateForm () {
      const body = {
        title: this.notification.title,
        is_enabled: this.notification.is_enabled,
        sorting_type: this.notification.sorting_type,
        density: this.notification.density,
        streams: this.notification.streams.map(stream => stream.id),
        days: this.notification.days,
        timezone: this.notification.timezone,
        schedules: this.notification.schedules,
        no_new_mentions_email: this.notification.no_new_mentions_email,
        summarize: this.notification.summarize
      }

      this.form.merge(body)
    },

    async fetchStreams () {
      try {
        this.isFetchingStreams = true

        this.streams = await UserApiService.fetchAvailableStreams()
      } catch (err) {
        this.$displayRequestError(err)
      } finally {
        this.isFetchingStreams = false
      }
    },

    async addTimeSchedule () {
      this.form.schedules.push({
        time: this.times.find(i => i.disabled === false).value
      })
    },

    async removeTimeSchedule (schedule) {
      await this.$confirm(this.$t('components.media_notification_management_modal.confirm_time_schedule_removal'))

      const index = this.form.schedules.map(item => item.time).indexOf(schedule.time)

      this.$delete(this.form.schedules, index)
    },

    async submit () {
      this.$v.form.$touch()

      if (this.$v.form.$error) return

      try {
        if (this.isNew) {
          await this.form.submit(MediaNotificationsApiService.create)
        } else {
          await this.form.submit((payload) => MediaNotificationsApiService.update(this.notification.id, payload))
        }

        this.$emit('update')
      } catch (err) {
        this.$displayRequestError(err)
      }
    },

    closed () {
      this.$emit('close')

      this.form.reset()

      this.$v.form.$reset()
    }
  }
}
</script>
